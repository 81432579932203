import axios from 'axios';
import { BASE_URL, BASE_URL_DEV, BASE_URL_YC } from '../constants';

// Инициализируем состояние из localStorage
let isDevelopmentMode = localStorage.getItem('isDevelopmentMode') === 'true';

// Модифицируем функцию getBaseUrl
const getBaseUrl = () => {
    const { hostname } = window.location;

    if (hostname === 'admin.confyday.com' || hostname === 'localhost') {
        return isDevelopmentMode ? BASE_URL_DEV : BASE_URL;
    }

    if (hostname === 'admin.wisme.app') {
        return BASE_URL_YC;
    }
};

// Модифицируем функцию переключения режима
export const toggleDevelopmentMode = () => {
    isDevelopmentMode = !isDevelopmentMode;
    localStorage.setItem('isDevelopmentMode', isDevelopmentMode);
    return isDevelopmentMode;
};

export const API = ({ url, method, headers, data, params }) => {
    try {
        return axios({
            method,
            url: getBaseUrl() + url,
            headers,
            timeout: 120000,
            data,
            params,
        });
    } catch (e) {
        alert(JSON.stringify(e.message));
        console.error(e);
    }
};

// Устанавливаем токены авторизации по умолчанию
axios.defaults.headers.common['authtoken'] = localStorage.getItem('token');
axios.defaults.headers.common['refreshtoken'] = localStorage.getItem('refreshToken');
