import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, ControlLabel, ButtonToolbar, Button, Uploader, Icon } from 'rsuite';
import { API } from '../../helpers/api';

const AddPost = () => {
    const [body, setBody] = useState('');
    const [previewPictures, setPreviewPictures] = useState([]);
    const [nameCategory, setNameCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [adminAuthors, setAdminAuthors] = useState([]);
    const [pickedUserAuthor, setPickedUserAuthor] = useState(null);

    const history = useHistory();

    useEffect(() => {
        fetchCategories();
        fetchAuthors();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await API({ url: 'v1/blog/categories/admin', method: 'GET' });
            const categoryNames = response.data.map((item) => item.alias);
            setCategories(['', ...categoryNames]);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchAuthors = async () => {
        try {
            const response = await API({ url: 'v1/users/admin/authors', method: 'GET' });
            setAdminAuthors(['', ...response.data.users]);
        } catch (error) {
            console.error('Error fetching authors:', error);
        }
    };

    const createArticleRequestBody = (post) => {
        const requestBody = new FormData();
        requestBody.append('body', post.body);
        requestBody.append('community', post.community);
        return requestBody;
    };

    const createPost = async () => {
        let userAuthor = null;
        if (!pickedUserAuthor) {
            userAuthor = adminAuthors.find((i) => i.id === 5);
        } else {
            userAuthor = adminAuthors.find((i) => i.nickName === pickedUserAuthor);
        }

        const { data } = await API({
            url: 'v1/community/admin/posts',
            method: 'POST',
            data: {
                body,
                user: userAuthor,
                category_name: nameCategory,
            },
        });

        if (previewPictures.length) {
            for (const { blobFile } of previewPictures) {
                console.log(blobFile);
                data['community'] = blobFile;

                await API({
                    url: 'v1/community/admin/upload-images',
                    method: 'POST',
                    data: createArticleRequestBody(data),
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        id: data.id,
                        userId: data.user_data.id,
                    },
                });
            }
        }

        history.push('/community/posts');
    };

    const handleSelectFiles = (files) => {
        if (files.length > 0) {
            return setPreviewPictures(files);
        }
        setPreviewPictures(null);
    };

    return (
        <div style={{ position: 'relative' }}>
            <h2>Create Post</h2>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Uploader
                    onChange={handleSelectFiles}
                    listType="picture"
                    accept=".png,.jpg,.jpeg,.webp,.mp4,.avi,.mov"
                    autoUpload={false}
                    multiple
                >
                    <button type="button">
                        <Icon icon="camera-retro" size="lg" />
                    </button>
                </Uploader>

                <br />
                <br />

                <p>Category</p>
                <select value={nameCategory} onChange={(e) => setNameCategory(e.target.value)}>
                    {categories.map((item, index) => {
                        return <option key={index}>{item}</option>;
                    })}
                </select>

                <br />
                <br />

                <p>Authors</p>
                <select
                    value={pickedUserAuthor}
                    onChange={(e) => setPickedUserAuthor(e.target.value)}
                >
                    {adminAuthors.map((item, index) => {
                        return <option key={index}>{item.nickName}</option>;
                    })}
                </select>

                <br />
                <br />

                <ControlLabel>
                    <textarea
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                        rows={6}
                        cols={70}
                    />
                </ControlLabel>

                <br />
                <br />
                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createPost} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default AddPost;
