import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const EditTip = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [tip, setTip] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [adminAuthors, setAdminAuthors] = useState([]);
    const [pickedUserAuthor, setPickedUserAuthor] = useState(null);
    const [pickedPhoto, setPickedPhoto] = useState('');
    const history = useHistory();

    const getUserAuthors = () => {
        API({ url: 'v1/users/admin/authors', method: 'GET' }).then((res) => {
            res.data.users.unshift({});
            setAdminAuthors(res.data.users);

            API({
                url: `v1/journey/admin/tips/${getLastUrlPath()}`,
                method: 'GET',
            }).then((res2) => {
                if (res2.data) {
                    const author = res.data.users.find(
                        (i) => i.image_link === res2.data.author_photo,
                    );

                    if (author) {
                        setPickedUserAuthor(author.name);
                    }

                    setTitle(res2.data.title);
                    setSubtitle(res2.data.subtitle);
                    setPickedPhoto(res2.data.author_photo);
                    setTip(res2.data.body);
                }
            });
        });
    };

    useEffect(() => {}, []);

    useEffect(() => {
        getUserAuthors();
    }, []);

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const createTip = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/admin/tips',
            method: 'PUT',
            data: {
                id: Number(getLastUrlPath()),
                title,
                subtitle,
                author_photo: pickedPhoto,
                body: tip,
            },
        });

        history.push('/actions/list');
    };

    return (
        <div>
            <h2>Edit Action</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Tip</ControlLabel>
                    <textarea
                        style={{ width: '700px' }}
                        rows={10}
                        onChange={(e) => setTip(e.target.value)}
                        value={tip}
                    ></textarea>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createTip} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default EditTip;
