import { makeAutoObservable } from 'mobx';

class WebSocketStore {
    socket = null;
    isConnected = false;
    messages = [];

    constructor() {
        makeAutoObservable(this);
        // this.connect();
    }

    connect = () => {
        const token = localStorage.getItem('token');

        // Создаем URL объект для WebSocket соединения
        // const wsUrl = new URL('wss://api-dev.confyday.com/ws');

        this.socket = new WebSocket(`http://localhost:8080/ws?token=${token}`);

        this.socket.addEventListener('open', () => {
            this.setIsConnected(true);
            console.log('WebSocket соединение установлено');
        });

        this.socket.addEventListener('message', (event) => {
            let message;
            try {
                message = JSON.parse(event.data);
                this.addMessage(message);
                console.log('Получены данные:', message);
            } catch (error) {
                console.error('Ошибка парсинга JSON:', error);
                console.log('Полученные данные:', event.data); // Логируем необработанные данные
            }
        });

        this.socket.addEventListener('error', (error) => {
            console.error('WebSocket ошибка:', error);
        });

        this.socket.addEventListener('close', () => {
            this.setIsConnected(false);
            console.log('WebSocket соединение закрыто');
            // Пробуем переподключиться через 5 секунд
            setTimeout(() => {
                if (!this.isConnected) {
                    this.connect();
                }
            }, 5000);
        });
    };

    setIsConnected = (status) => {
        this.isConnected = status;
    };

    addMessage = (message) => {
        this.messages.push(message);
    };

    sendMessage = (message) => {
        if (this.isConnected && this.socket) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket не подключен');
        }
    };

    disconnect = () => {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    };
}

export default WebSocketStore;
