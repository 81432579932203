import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const Edit = () => {
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [rules, setRules] = useState([]);
    const [ruleId, setRuleId] = useState([]);
    const history = useHistory();

    useEffect(() => {
        API({
            url: `v1/journey/karma/rules/${getLastUrlPath()}`,
            method: 'GET',
        }).then((res) => {
            if (res.data) {
                setTitle(res.data.rule_name);
                setRules(res.data.rules);
                setRuleId(res.data.id);
            }
        });
    }, []);

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }
        if (isError) {
            return false;
        }

        return true;
    };

    const addRule = () => {
        const _rules = [...rules];
        _rules.push('');
        setRules(_rules);
    };

    const changeRules = (val, idx) => {
        let _rules = [...rules];
        _rules[idx] = val;
        setRules(_rules);
    };

    const deleteRule = (idx) => {
        let _rules = [...rules];
        _rules.splice(idx, 1);
        setRules(_rules);
    };

    const createAffiliate = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/karma/rules',
            method: 'PUT',
            data: {
                id: ruleId,
                ruleName: title,
                rules,
            },
        });

        history.push('/awards/list');
    };

    return (
        <div>
            <h2>Edit Award Rules</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                {rules.map((item, index) => {
                    return (
                        <FormGroup key={index}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    value={item}
                                    onChange={(e) => changeRules(e.target.value, index)}
                                    style={{ flex: 1 }}
                                />
                                <Button
                                    appearance="subtle"
                                    color="red"
                                    onClick={() => deleteRule(index)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Удалить
                                </Button>
                            </div>
                        </FormGroup>
                    );
                })}

                <FormGroup>
                    <Button onClick={addRule} appearance="primary">
                        Add
                    </Button>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createAffiliate} appearance="primary">
                            Update
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
                <br />
                <br />
            </Form>
        </div>
    );
};

export default Edit;
