import { useEffect, useState } from 'react';
import { Table, Button, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import styles from './Verification.module.scss';

const Challenges = () => {
    const history = useHistory();
    const [challenges, setChallenges] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    const getChallenges = async () => {
        try {
            setTableLoading(true);
            const { data } = await API({ url: 'v1/challenges/admin/list', method: 'GET' });

            if (data?.challenges) {
                setChallenges(data.challenges.filter((item) => item.status_review === 'pending'));
            }
        } catch (error) {
            console.error(error);
        }

        setTableLoading(false);
    };

    useEffect(() => {
        getChallenges();
    }, []);

    const handleEdit = (type, id) => {
        const currentPath = history.location.pathname;
        const basePath = currentPath.replace('/verification', '');
        const path = [basePath, 'list', id];
        history.push(path.join('/'));
    };

    const handleUserProfile = (userId) => {
        history.push(`/users/${userId}`);
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => <div>{text?.toUpperCase()}</div>,
            width: 120,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
        },
        {
            title: 'Description',
            key: 'routine',
            width: 600,
            render: (item) => {
                return <div>{item.type === 'habit' ? item.routine : item.description}</div>;
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (item) => (
                <div className={styles.statusCont}>
                    <Tag color={item.status_review === 'pending' ? 'gold' : 'red'}>
                        {item.status_review}
                    </Tag>
                </div>
            ),
            width: 400,
        },
        {
            title: 'User Profile',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (userId) => (
                <div className={styles.leftAligned}>
                    <Button type="link" onClick={() => handleUserProfile(userId)}>
                        View Profile
                    </Button>
                </div>
            ),
            width: 150,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        icon={<i className="fas fa-pencil-alt" />}
                        onClick={() => handleEdit(record.type, record.id)}
                    >
                        Open
                    </Button>
                );
            },
            width: 80,
        },
    ];

    return (
        <div className={styles.tableContainer}>
            <h2>Awaiting verification</h2>
            <Table
                loading={tableLoading}
                dataSource={challenges}
                columns={columns}
                rowKey="id"
                pagination={false}
                bordered
                rowClassName={(record) => styles.tableRow}
                size="small"
                virtual={false}
                sticky={false}
                getPopupContainer={(trigger) => trigger.parentNode}
                components={{
                    body: {
                        cell: ({ children, ...restProps }) => <td {...restProps}>{children}</td>,
                    },
                }}
            />
        </div>
    );
};

export default Challenges;
