import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Form,
    Input,
    Table,
    notification,
    Empty,
    Popconfirm,
    Select,
    Radio,
} from 'antd';
import { API } from '../../helpers/api';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import 'moment/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FileUploader from '../../components/FileUploader';
import EventSubscribers from '../../components/EventSubscribers';

const Events = () => {
    const [events, setEvents] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingEvent, setEditingEvent] = useState(null);
    const [mapVisible, setMapVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationValue, setLocationValue] = useState('');
    const [authors, setAuthors] = useState([]);
    const [subscribersModalVisible, setSubscribersModalVisible] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);

    const history = useHistory();

    useEffect(() => {
        fetchEvents();
        getAuthors();
    }, []);

    useEffect(() => {
        if (isModalVisible && !editingEvent) {
            form.resetFields();
            form.setFieldsValue({
                type: 'online',
            });
        }
    }, [isModalVisible, form, editingEvent]);

    const getAuthors = async () => {
        try {
            const { data } = await API({ url: 'v1/users/admin/authors', method: 'GET' });

            setAuthors(data.users);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await API({
                url: 'v1/admin/events',
                method: 'GET',
            });
            setEvents(response.data.items);
        } catch (error) {
            console.error('Error fetching events:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось загрузить события.',
            });
        }
    };

    const handleSaveEvent = async (values) => {
        try {
            const formData = {
                name: values.name,
                type: values.type,
                cost: Number(values.cost),
                start_time: moment(values.start_time).format(),
                end_time: moment(values.end_time).format(),
                user_id: values.userId,
            };

            if (values.location) {
                formData.location = `(${values.location.split(',')[0].trim()}, ${values.location
                    .split(',')[1]
                    .trim()})`;
            }

            if (values.address) {
                formData.address = values.address;
            }

            let response;
            if (editingEvent?.id) {
                response = await API({
                    url: `v1/admin/events/${editingEvent.id}`,
                    method: 'PUT',
                    data: formData,
                });
            } else {
                response = await API({
                    url: 'v1/admin/events',
                    method: 'POST',
                    data: formData,
                });
            }

            if (values.uploadFormData) {
                await API({
                    url: `v1/admin/events/${response.data.id}/photo/upload`,
                    method: 'PUT',
                    data: values.uploadFormData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        id: response.data.id,
                        userId: response.data.user_id,
                    },
                });
            }

            notification.success({
                message: 'Успех',
                description: editingEvent?.id
                    ? 'Событие успешно обновлено!'
                    : 'Событие успешно создано!',
            });

            setIsModalVisible(false);
            form.resetFields();
            setEditingEvent(null);
            fetchEvents();
        } catch (error) {
            console.error('Error saving event:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось сохранить событие.',
            });
        }
    };

    const handleDeleteEvent = async (id) => {
        try {
            await API({
                url: `v1/admin/events/${id}`,
                method: 'DELETE',
            });
            notification.success({
                message: 'Успех',
                description: 'Событие успешно удалено!',
            });
            fetchEvents();
        } catch (error) {
            console.error('Error deleting event:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось удалить событие.',
            });
        }
    };

    const showModal = (event = null) => {
        if (event) {
            console.log('Event attachments:', event.attachments);
            setEditingEvent(event);
            if (event.location) {
                const locationString = `${event.location.x}, ${event.location.y}`;
                setSelectedLocation([event.location.x, event.location.y]);
                setLocationValue(locationString);
            } else {
                setSelectedLocation(null);
                setLocationValue('');
            }

            // Преобразуем массив URL в формат, который ожидает FileUploader
            const formattedAttachments =
                event.attachments?.map((url) => ({
                    url: url,
                    name: url.split('/').pop(), // Извлекаем имя файла из URL
                    status: 'done',
                })) || [];

            form.setFieldsValue({
                name: event.name,
                type: event.type,
                cost: event.cost,
                start_time: moment(event.start_time),
                end_time: moment(event.end_time),
                location: event.location ? `${event.location.x}, ${event.location.y}` : '',
                userId: event.user_id,
                attachments: formattedAttachments,
                address: event.address,
            });
            console.log('Formatted attachments:', formattedAttachments);
        } else {
            setEditingEvent(null);
            setSelectedLocation(null);
            setLocationValue('');
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setEditingEvent(null);
        setSelectedLocation(null);
        setLocationValue('');
    };

    const columns = [
        {
            title: 'Название события',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Тип события',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Стоимость',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Время начала',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm'),
        },
        {
            title: 'Время окончания',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm'),
        },
        {
            title: 'Местоположение',
            dataIndex: 'location',
            key: 'location',
            render: (location) => {
                if (location && location.x && location.y) {
                    return `${location.x}, ${location.y}`;
                }
                return 'Не указано';
            },
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            render: (address) => address || 'Не указан',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            render: (_, record) => {
                const author = authors.find((a) => a.id === record.user_id);
                if (!author) return 'Не указан';
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            history.push(`/users/${author.id}`);
                        }}
                    >
                        {author.nickName}
                    </Button>
                );
            },
        },
        {
            title: 'Рейтинг',
            dataIndex: 'rating',
            key: 'rating',
            render: (rating) => {
                if (rating) {
                    return rating.toFixed(1);
                }
                return '0';
            },
        },
        {
            title: 'Дата создания',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format('DD.MM.YYYY HH:mm'),
        },
        {
            title: 'Подписчики',
            key: 'subscribers',
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Button
                        onClick={() => {
                            setSelectedEventId(record.id);
                            setSubscribersModalVisible(true);
                        }}
                    >
                        Показать подписчиков
                    </Button>
                    <span>({record.count || 0})</span>
                </div>
            ),
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (_, record) => (
                <div>
                    <Button type="primary" onClick={() => showModal(record)}>
                        Редактировать
                    </Button>
                    <Popconfirm
                        title="Вы уверены, что хотите удалить это событие?"
                        onConfirm={() => handleDeleteEvent(record.id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button type="danger">Удалить</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const handleMapClick = (e) => {
        const coords = e.get('coords');

        setSelectedLocation(coords);
        const locationString = `${coords[0].toFixed(6)}, ${coords[1].toFixed(6)}`;

        setLocationValue(locationString);
        form.setFieldsValue({
            location: locationString,
        });

        setMapVisible(false);
    };

    return (
        <div>
            <h2>Events</h2>

            <Button type="primary" onClick={showModal} style={{ marginBottom: '20px' }}>
                Создать событие
            </Button>

            {events.length === 0 ? (
                <Empty description="События не найдены" />
            ) : (
                <Table
                    dataSource={events}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    // scroll={{ y: 400 }}
                />
            )}

            <Modal
                title={editingEvent?.id ? 'Редактировать событие' : 'Создать событие'}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={600}
                style={{ top: 20 }}
                styles={{
                    body: {
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'auto',
                        position: 'relative',
                    },
                }}
                zIndex={1000}
            >
                <Form
                    form={form}
                    initialValues={{
                        type: 'online',
                    }}
                    onFinish={handleSaveEvent}
                    layout="vertical"
                >
                    <Form.Item
                        label="Название события"
                        name="name"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите название события!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Тип события"
                        name="type"
                        rules={[{ required: true, message: 'Пожалуйста, выберите тип события!' }]}
                    >
                        <Radio.Group>
                            <Radio.Button value="online">Онлайн</Radio.Button>
                            <Radio.Button value="offline">Офлайн</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Стоимость участия"
                        name="cost"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите стоимость участия!' },
                        ]}
                    >
                        <Input type="number" min={0} />
                    </Form.Item>

                    <Form.Item label="Адрес" name="address" rules={[{ required: false }]}>
                        <Input placeholder="Введите адрес события" />
                    </Form.Item>

                    <Form.Item
                        label="Время начала"
                        name="start_time"
                        rules={[{ required: true, message: 'Пожалуйста, выберите время начала!' }]}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
                            <DateTimePicker
                                value={form.getFieldValue('start_time') || null}
                                onChange={(newValue) => {
                                    form.setFieldsValue({ start_time: newValue });
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: false,
                                        size: 'small',
                                        sx: {
                                            width: '200px',
                                            '& .MuiInputBase-root': {
                                                '&::before': {
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                                                },
                                            },
                                        },
                                    },
                                }}
                                ampm={false}
                                format="DD.MM.YYYY HH:mm"
                                PopperProps={{
                                    style: { zIndex: 1500 },
                                }}
                            />
                        </LocalizationProvider>
                    </Form.Item>

                    <Form.Item
                        label="Время окончания"
                        name="end_time"
                        rules={[
                            { required: true, message: 'Пожалуйста, выберите время окончания!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        !getFieldValue('start_time') ||
                                        moment(value).isAfter(getFieldValue('start_time'))
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'Время окончания должно быть позже времени начала!',
                                        ),
                                    );
                                },
                            }),
                        ]}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
                            <DateTimePicker
                                value={form.getFieldValue('end_time') || null}
                                onChange={(newValue) => {
                                    form.setFieldsValue({ end_time: newValue });
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: false,
                                        size: 'small',
                                        sx: {
                                            width: '200px',
                                            '& .MuiInputBase-root': {
                                                '&::before': {
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
                                                },
                                            },
                                        },
                                    },
                                }}
                                ampm={false}
                                format="DD.MM.YYYY HH:mm"
                                PopperProps={{
                                    style: { zIndex: 1500 },
                                }}
                            />
                        </LocalizationProvider>
                    </Form.Item>

                    <Form.Item
                        label="Местоположение события"
                        name="location"
                        rules={[{ required: false }]}
                    >
                        <div>
                            <Input.Group compact>
                                <Input
                                    style={{ width: 'calc(100% - 130px)', marginRight: '8px' }}
                                    placeholder="(широта, долгота)"
                                    disabled
                                    value={locationValue}
                                />
                                <Button onClick={() => setMapVisible(true)}>
                                    Выбрать на карте
                                </Button>
                            </Input.Group>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Автор события"
                        name="userId"
                        rules={[
                            { required: true, message: 'Пожалуйста, выберите автора события!' },
                        ]}
                    >
                        <Select>
                            {authors.map((author) => (
                                <Select.Option key={author.id} value={author.id}>
                                    {author.nickName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Медиафайлы">
                        <FileUploader
                            onUpload={(formData, files) => {
                                form.setFieldValue('uploadFormData', formData);
                            }}
                            fieldName="photos"
                            existingFiles={form.getFieldValue('attachments')}
                        />
                    </Form.Item>

                    <Button type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form>
            </Modal>

            <Modal
                title="Выберите местоположение"
                open={mapVisible}
                onCancel={() => setMapVisible(false)}
                footer={null}
                width={800}
                style={{ top: 20 }}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
            >
                <div style={{ height: '400px', width: '100%' }}>
                    <YMaps>
                        <Map
                            defaultState={{
                                center: [55.75, 37.57],
                                zoom: 9,
                            }}
                            width="100%"
                            height="100%"
                            onClick={handleMapClick}
                        >
                            {selectedLocation && <Placemark geometry={selectedLocation} />}
                        </Map>
                    </YMaps>
                </div>
            </Modal>

            <EventSubscribers
                eventId={selectedEventId}
                visible={subscribersModalVisible}
                onClose={() => {
                    setSubscribersModalVisible(false);
                    setSelectedEventId(null);
                }}
            />
        </div>
    );
};

export default Events;
