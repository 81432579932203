import { useEffect, useState, useContext } from 'react';
import { Button, Typography, List } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import { getLastUrlPath } from '../../helpers/getLastUrlPath';
import styles from './ChallengesItem.module.scss';
import { StoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
const { Title } = Typography;

const ChallengesItem = observer(() => {
    const { authorsStore } = useContext(StoreContext);
    const [author, setAuthor] = useState(null);

    const [challenge, setChallenge] = useState({});
    const history = useHistory();

    useEffect(() => {
        const idEntity = getLastUrlPath(1);

        const fetchChallenge = async () => {
            try {
                const { data } = await API({
                    url: `v1/challenges/admin/${idEntity}`,
                    method: 'GET',
                });
                setChallenge(data);

                setAuthor(authorsStore.getAuthor(data.user));
            } catch (e) {
                console.error(e);
            }
        };

        fetchChallenge();
    }, []);

    const changeStatus = async (status) => {
        const idEntity = getLastUrlPath(1);

        try {
            await API({
                url: `v1/challenges/admin/${idEntity}`,
                method: 'PATCH',
                data: { status },
            });
            history.push('/challenges/verification');
        } catch (e) {
            console.error(e);
        }
    };

    const handleUserProfile = () => {
        history.push(`/users/${challenge.user}`);
    };

    const typeEntity = getLastUrlPath(2);

    return (
        <div className={styles.container}>
            <Title level={2}>Name: {challenge.name}</Title>
            <div className={styles.desc}>
                <strong>Description:</strong> {challenge.description || challenge.routine}
            </div>
            <br />
            <div className={styles.desc}>
                <strong>Type:</strong> {typeEntity}
            </div>
            <br />
            {challenge.photo && (
                <div className={styles.img}>
                    <img src={challenge.photo} alt="Challenge" />
                </div>
            )}

            <div className={styles.author}>
                <Button
                    type="link"
                    onClick={() => {
                        history.push(`/users/${challenge.user}`);
                    }}
                >
                    Creator
                </Button>
            </div>

            {challenge.type_habit && (
                <div className={styles.wellness}>
                    <p>
                        <strong>Type habit:</strong>
                    </p>
                    <div className={styles.wellnessDesc}>{challenge.type_habit}</div>
                </div>
            )}

            {challenge.wellness && (
                <div className={styles.wellness}>
                    <p>
                        <strong>Wellness:</strong>
                    </p>
                    <div className={styles.wellnessDesc}>{challenge.wellness}</div>
                </div>
            )}

            {challenge.duration && (
                <div className={styles.wellness}>
                    <p>
                        <strong>Duration:</strong>
                    </p>
                    <div className={styles.wellnessDesc}>{challenge.duration}</div>
                </div>
            )}

            {challenge.start_date && (
                <div className={styles.wellness}>
                    <p>
                        <strong>Start date:</strong>
                    </p>
                    <div className={styles.wellnessDesc}>
                        {format(new Date(challenge.start_date), 'dd.MM.yyyy')}
                    </div>
                </div>
            )}

            {challenge.end_date && (
                <div className={styles.wellness}>
                    <p>
                        <strong>End date:</strong>
                    </p>
                    <div className={styles.wellnessDesc}>
                        {format(new Date(challenge.end_date), 'dd.MM.yyyy')}
                    </div>
                </div>
            )}

            {challenge.triggers && challenge.triggers.length > 0 && (
                <>
                    <div>
                        <strong>TRIGGERS:</strong>
                    </div>
                    <List
                        dataSource={challenge.triggers}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                    <br />
                </>
            )}

            {challenge.rewards && challenge.rewards.length > 0 && (
                <>
                    <div>
                        <strong>REWARDS:</strong>
                    </div>
                    <List
                        dataSource={challenge.rewards}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                    <br />
                </>
            )}

            {challenge.alternatives && challenge.alternatives.length > 0 && (
                <>
                    <div>
                        <strong>ALTERNATIVES:</strong>
                    </div>
                    <List
                        dataSource={challenge.alternatives}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.steps && challenge.steps.length > 0 && (
                <>
                    <div>
                        <strong>STEPS:</strong>
                    </div>
                    <List
                        dataSource={challenge.steps}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.opportunities && (
                <>
                    <div>
                        <strong>OPPORTUNITIES:</strong>
                    </div>
                    <List
                        dataSource={challenge.opportunities}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.tags && (
                <>
                    <div>
                        <strong>TAGS:</strong>
                    </div>
                    <List
                        dataSource={challenge.tags}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.notes && challenge.notes.length > 0 && (
                <>
                    <div>
                        <strong>NOTES:</strong>
                    </div>
                    <List
                        dataSource={challenge.notes}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <div className={styles.btnCont}>
                <Button
                    type="primary"
                    onClick={() => changeStatus('approved')}
                    style={{ marginRight: '10px' }}
                >
                    Accept
                </Button>
                <Button type="default" onClick={() => changeStatus('rejected')}>
                    Decline
                </Button>
                <Button type="primary" onClick={handleUserProfile}>
                    View profile
                </Button>
            </div>
        </div>
    );
});

export default ChallengesItem;
