import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreProvider } from './stores/RootStore';

ReactDOM.render(
    <StoreProvider>
        <Router>
            <App />
        </Router>
    </StoreProvider>,
    document.getElementById('root'),
);
