import React, { useState, useEffect } from 'react';
import { Uploader, Icon } from 'rsuite';

const FileUploader = ({
    onUpload,
    fieldName = 'photos',
    accept = '.png,.jpg,.jpeg,.webp,.mp4,.avi,.mov',
    multiple = true,
    existingFiles = [],
}) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        // Преобразуем существующие файлы в формат, который понимает Uploader
        const formattedFiles = existingFiles.map((file) => ({
            fileKey: file.uid || file.name, // Уникальный ключ файла
            name: file.name,
            url: file.url,
            status: 'finished', // rsuite использует 'finished' вместо 'done'
        }));
        setFiles(formattedFiles);
    }, [existingFiles]);

    const handleFileSelect = (fileList) => {
        setFiles(fileList);

        // Создаем FormData для загрузки
        const formData = new FormData();
        fileList.forEach((file) => {
            if (file?.blobFile) {
                formData.append(fieldName, file.blobFile);
            }
        });

        // Вызываем callback с готовым FormData и списком файлов
        onUpload(formData, fileList);
    };

    return (
        <Uploader
            fileList={files}
            onChange={handleFileSelect}
            listType="picture"
            accept={accept}
            autoUpload={false}
            multiple={multiple}
            disabled={false}
            disabledFileItem={false}
            preventDefaultEvent
            shouldQueueUpdate={() => true}
            defaultFileList={files}
        >
            <button type="button">
                <Icon icon="camera-retro" size="lg" />
            </button>
        </Uploader>
    );
};

export default FileUploader;
