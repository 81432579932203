import React, { createContext } from 'react';
import WebSocketStore from './WebSocketStore';
import AuthorsStore from './AuthorsStore';

class RootStore {
    constructor() {
        this.webSocketStore = new WebSocketStore();
        this.authorsStore = new AuthorsStore();
    }
}

// Создаем контекст
export const StoreContext = createContext(null);

// Создаем провайдер
export const StoreProvider = ({ children }) => {
    const store = new RootStore();

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
