import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, notification } from 'antd';
import { API } from '../../helpers/api';
import moment from 'moment';

const EventSubscribers = ({ eventId, visible, onClose }) => {
    const [subscribers, setSubscribers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible && eventId) {
            fetchParticipants();
        }
    }, [visible, eventId]);

    const fetchParticipants = async () => {
        setLoading(true);
        try {
            const response = await API({
                url: `v1/admin/events/${eventId}/participants`,
                method: 'GET',
            });
            setSubscribers(response.data.participants || []);
        } catch (error) {
            console.error('Error fetching participants:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось загрузить список участников.',
            });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Никнейм',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Дата подписки',
            dataIndex: 'subscribed_at',
            key: 'subscribed_at',
            render: (text) => moment(text).format('DD.MM.YYYY HH:mm'),
        },
    ];

    return (
        <Modal
            title="Подписчики события"
            open={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <Table
                dataSource={subscribers}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />
        </Modal>
    );
};

export default EventSubscribers;
