import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';

const Create = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [rules, setRules] = useState([]);
    const history = useHistory();

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const addRule = () => {
        const _rules = [...rules];
        _rules.push('');
        setRules(_rules);
    };

    const changeRules = (val, idx) => {
        let _rules = [...rules];
        _rules[idx] = val;
        setRules(_rules);
    };

    const createAffiliate = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/karma/rules',
            method: 'POST',
            data: {
                ruleName: title,
                rules,
            },
        });

        history.push('/awards/list');
    };

    return (
        <div>
            <h2>New Rule</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                {rules.map((item, index) => {
                    return (
                        <FormGroup>
                            <input
                                value={item}
                                onChange={(e) => changeRules(e.target.value, index)}
                            />
                        </FormGroup>
                    );
                })}

                <FormGroup>
                    <Button onClick={addRule} appearance="primary">
                        Add
                    </Button>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createAffiliate} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default Create;
