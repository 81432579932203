import { makeAutoObservable } from 'mobx';
import { API } from '../helpers/api';

class AuthorsStore {
    authors = [];

    constructor() {
        makeAutoObservable(this);
        if (localStorage.getItem('token')) {
            this.getAuthors();
        }
    }

    setAuthors(authors) {
        this.authors = authors;
    }

    getAuthor(id) {
        return this.authors.find((author) => author.id === id);
    }

    getAuthorByNickName(nickName) {
        return this.authors.find((author) => author.nickName === nickName);
    }

    async getAuthors() {
        try {
            const { data } = await API({ url: 'v1/users/admin/authors', method: 'GET' });

            this.setAuthors(data.users);
        } catch (e) {
            console.log(e);
        }
    }
}

export default AuthorsStore;
