import React, { useState } from 'react';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';
import styles from './LoginForm.module.scss';
import axios from 'axios';

const LoginForm = ({ authorize }) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [isOtp, setIsOtp] = useState('');

    const getAuthorization = async () => {
        try {
            if (isOtp) {
                const res = await API({
                    url: 'v1/auth/admin/login',
                    method: 'POST',
                    data: {
                        email: login,
                        password,
                        otpCode,
                    },
                });

                if (res.data.tokens) {
                    const { token, refreshToken, expiresIn } = res.data.tokens;

                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('expiresIn', expiresIn);
                    axios.defaults.headers.common['authtoken'] = localStorage.getItem('token');
                    axios.defaults.headers.common['refreshtoken'] =
                        localStorage.getItem('refreshToken');

                    authorize();
                }
                setIsOtp(false);
            } else {
                await API({
                    url: 'v1/auth/admin/login/otp',
                    method: 'GET',
                });
                setIsOtp(true);
            }
        } catch (e) {
            alert('Server error');
        }
    };

    return (
        <div className={styles.loginForm}>
            <Form>
                <FormGroup>
                    <ControlLabel>Login</ControlLabel>
                    <FormControl onChange={(val) => setLogin(val)} name="name" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                        onChange={(val) => setPassword(val)}
                        name="password"
                        type="password"
                    />
                </FormGroup>

                {isOtp && (
                    <FormGroup>
                        <ControlLabel>Verification code</ControlLabel>
                        <FormControl onChange={(val) => setOtpCode(val)} name="otpCode" />
                    </FormGroup>
                )}

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={getAuthorization} appearance="primary">
                            {isOtp ? 'Enter' : 'Get code'}
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default LoginForm;
